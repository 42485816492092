
import Step0 from "@/components/wizard/Step0.vue";
import { Actions } from "@/store/enums/StoreEnums";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import { ElMessage } from "element-plus";
import { useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { mapGetters, useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface IStep0 {
  dni: string;
  telefono: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateAccount extends IStep0 {}

export default defineComponent({
  name: "kt-horizontal-wizard",
  components: {
    Step0,
  },

  setup() {
    const { t, te } = useI18n();

    const route = useRoute();
    const token = route.query.act;

    const formData = ref<CreateAccount>({
      dni: "",
      telefono: "",
    });

    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);

    onBeforeMount(() => {
      if (token) {
        module.fetchById({ formularioId: token as string });
      }
    });
    const datosServicio = computed(() => module.servicio);
    const comarcaId = ref<string>();
    const nombreActividad = ref<string>();

    watch(datosServicio, () => {
      if (!datosServicio.value) return;

      comarcaId.value = datosServicio.value.comarcaId;
      nombreActividad.value = datosServicio.value.nombre;
    });

    const i18n = (text) => (te(text) ? t(text) : text);

    const createAccountSchema = Yup.object({
      dni: Yup.string().required().label(i18n("FamiliaDNILabel")),
      telefono: Yup.string().required().label(i18n("FamiliaTelefonoLabel")),
    });
    const currentSchema = computed(() => {
      return createAccountSchema;
    });

    const { resetForm, handleSubmit } = useForm<IStep0>({
      validationSchema: currentSchema,
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      return formSubmit(formData.value);
    });

    const previousStep = () => {
      if (token) {
        const pathFijo = new String("/#/familias?act=");
        const pathVariable = token;
        window.location.href = pathFijo.concat(pathVariable.toString());
      } else {
        window.location.href = "/#/familias";
      }
    };

    const formSubmit = async (formData) => {
      formData.comarca = comarcaId.value;
      await store
        .dispatch(Actions.CHECK_FAMILIAS, formData)
        .then((respuesta) => {
          if (!token) {
            window.location.href = "/#home";
          }

          const contacto = { dni: formData.dni, telefono: formData.telefono };
          module.setContacto(contacto);

          if (respuesta.result === "ok") {
            const pathFijo = new String(
              "/#/formulario-familias-registradas?act="
            );
            const pathVariable = token || "";
            window.location.href = pathFijo.concat(pathVariable.toString());
          } else {
            const pathFijo = new String("/#/formulario-familias?act=");
            const pathVariable = token || "";
            window.location.href = pathFijo.concat(pathVariable.toString());
          }
        })
        .catch((err) => {
          console.error("Err", err);
          ElMessage.error(t("formularioValidacionFamiliaError"));
        });
    };
    return {
      previousStep,
      handleStep,
      token,
      nombreActividad,
    };
  },
  computed: {
    ...mapGetters(["isPendingForm"]),
  },
});
