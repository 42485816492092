
import { defineComponent } from "vue";
import { ErrorMessage, useField } from "vee-validate";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "step-1",
  components: {
    ErrorMessage,
  },
  setup() {
    const { t, te } = useI18n();

    const i18n = (text) => (te(text) ? t(text) : text);

    const { value: dni } = useField("dni");
    const { value: telefono } = useField("telefono");

    return {
      i18n,
      dni,
      telefono,
    };
  },
});
